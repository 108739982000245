'use client'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import {
  ConfigurableProductOptionFragment,
  ConfigurableProductVariantFragment,
  ProductStockStatus,
} from '@/api'
import { useCartContext } from '@/providers'
import { obsoloteGetPrice } from '@/utils'
import { ConfigurableVariant } from './obsolete-configurable-variant'
import {
  obsoleteGetHandleAttributeChange,
  obsoleteGetConfigurableVariantAttributesRecord,
} from './utils'
import { ProductDetailVariantsAddToCartButton } from './variants-add-to-cart-button'
import { VariantsCounter } from './variants-counter'
import { useAddToCartBtnText } from '@/common/hooks/use-add-to-cart-btn-text'
import { selectors } from '@/common/constants/selectors-constants'
import { useProductDataContext } from '@/providers/product-data/obsolote-product-data-context'
import { delayBeforeClosingProductModal } from '@/common/constants/products'

interface ProductConfigurableVariants {
  name?: string | null
  sku?: string | null
  manufacturer_info?: {
    name?: string | null
  } | null
  breadcrumb_en?: string | null
  configurable_variants?:
    | (ConfigurableProductVariantFragment | undefined | null)[]
    | null
  configurable_options?:
    | (ConfigurableProductOptionFragment | undefined | null)[]
    | null
}

export type ConfigurableVariantsProps = {
  configurableProductData: ProductConfigurableVariants
  isPopupVariant?: boolean
  onClose?: () => void
}

export function ConfigurableVariants({
  configurableProductData,
  isPopupVariant = false,
  onClose,
}: ConfigurableVariantsProps): JSX.Element {
  const {
    configurable_variants: configurableVariants,
    configurable_options: configurableOptions,
  } = configurableProductData

  const [changeText, setChangeText] = useState(false)

  const {
    configurableProductVariant,
    setConfigurableProductVariant,
    productVariantCount,
    setProductVariantCount,
  } = useProductDataContext()
  const { isAddingToCart, isCartRefetching, addToCart, isCartFetchError } =
    useCartContext()
  const disabled = isCartRefetching || !addToCart

  const options = useMemo(
    () =>
      (configurableOptions
        ?.filter((option) => !!option)
        ?.sort(
          (optionA, optionB) =>
            (optionA?.position ?? 0) - (optionB?.position ?? 0),
        ) ?? []) as ConfigurableProductOptionFragment[],
    [configurableOptions],
  )
  const variants = useMemo(
    () =>
      (configurableVariants?.filter(
        (variant) =>
          variant?.product?.enabled &&
          variant?.product?.stock_status === ProductStockStatus.InStock,
      ) ?? []) as ConfigurableProductVariantFragment[],
    [configurableVariants],
  )

  const isMultipleVariants = useMemo(
    () =>
      variants?.every((variant) =>
        variant?.attributes?.every(
          (attribute) => attribute?.label !== 'single_variant',
        ),
      ),
    [variants],
  )

  // All possible values (attributes) corresponding with selected configurableProductVariant
  // in a structure Record<productOption.attribute_code, ProductConfigurableVariantAttribute>
  const variantAttributesRecord = useMemo(
    () =>
      obsoleteGetConfigurableVariantAttributesRecord({
        options,
        variants,
        selectedVariant: configurableProductVariant,
      }),
    [options, variants, configurableProductVariant],
  )

  const handleAttributeChangeForVariant = useMemo(
    () =>
      obsoleteGetHandleAttributeChange(
        configurableProductVariant,
        variants,
        setConfigurableProductVariant,
      ),
    [configurableProductVariant, variants, setConfigurableProductVariant],
  )

  const handleAddToCart = useCallback(async () => {
    if (addToCart) {
      const { finalPrice } = obsoloteGetPrice(
        configurableProductVariant?.product,
      )

      setChangeText(true)

      await addToCart({
        productName: configurableProductData?.name ?? '',
        parentSku: configurableProductData?.sku ?? '',
        sku: configurableProductVariant?.product?.sku ?? '',
        quantity: productVariantCount,
        brand: configurableProductData?.manufacturer_info?.name ?? '',
        price: finalPrice?.value_excl_tax
          ? Math.round(finalPrice?.value_excl_tax * 100) / 100
          : NaN,
        category: configurableProductData?.breadcrumb_en ?? '',
      })
    }

    if (onClose) {
      setTimeout(() => onClose(), delayBeforeClosingProductModal)
    }
  }, [
    configurableProductData,
    configurableProductVariant,
    productVariantCount,
    addToCart,
    onClose,
  ])

  useEffect(() => {
    if (!disabled) {
      setChangeText(false)
    }
  }, [disabled])

  const buttonText = useAddToCartBtnText({
    changeText,
    isAddingToCart,
    isCartRefetching,
    isCartFetchError,
  })

  return (
    <>
      <div className="mb-4 flex flex-wrap">
        <div
          className={twMerge(
            isPopupVariant ? 'flex-col' : 'flex-col md:flex-row',
            'w-full flex flex-wrap',
          )}
          data-test={selectors.HP.productVariantSection}
        >
          {isMultipleVariants &&
            options.map((option) => (
              <ConfigurableVariant
                key={option.attribute_code}
                disabled={disabled}
                option={option}
                configurableProductVariant={configurableProductVariant}
                variantAttributesRecord={variantAttributesRecord}
                handleAttributeChangeForVariant={
                  handleAttributeChangeForVariant
                }
                className={
                  isPopupVariant
                    ? 'w-full'
                    : 'w-full md:w-fit max-w-none md:max-w-[180px]'
                }
              />
            ))}
          <VariantsCounter
            count={productVariantCount}
            onCountChange={setProductVariantCount}
            onAddToCart={handleAddToCart}
            isDisabled={disabled}
            className={isPopupVariant ? 'w-[120px]' : 'w-[120px] md:w-[105px]'}
          />
        </div>
      </div>
      <ProductDetailVariantsAddToCartButton
        className="md:w-full lg:w-full"
        addToCart={handleAddToCart}
        buttonText={buttonText}
      />
    </>
  )
}
