import { DeliveryDateData } from '@/api'
import { useStoreContext } from '@/providers'
import { BadgeDeliveryDate } from './badge-delivery-date'
import { BadgeExpiration } from './badge-expiration-date'
import { BadgeStockStatus } from './badge-stock-status'
import { useProductDataContext } from '@/providers/product-data/obsolote-product-data-context'

export interface ProductDetailStorageBadgesProps {
  inStock: boolean
  longTermUnavailable: boolean
  isGiftCardProduct: boolean
  deliveryDateData?: DeliveryDateData
}

export const ProductDetailStorageBadges = ({
  inStock,
  longTermUnavailable,
  isGiftCardProduct,
  deliveryDateData,
}: ProductDetailStorageBadgesProps) => {
  const { configurableProductVariant } = useProductDataContext()
  const { storeCode } = useStoreContext()

  const expirationDate = configurableProductVariant?.product?.expiration_date
  const isGeneralMarket = storeCode === 'gymbeamcom'

  return (
    <div className="flex flex-wrap gap-2 my-2">
      <BadgeStockStatus
        inStock={inStock}
        longTermUnavailable={longTermUnavailable}
      />
      {inStock && deliveryDateData && !isGeneralMarket && (
        <BadgeDeliveryDate
          deliveryDateData={deliveryDateData}
          isGiftCardProduct={isGiftCardProduct}
        />
      )}
      {expirationDate && <BadgeExpiration expirationDate={expirationDate} />}
    </div>
  )
}
