'use client'

import { useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Alert } from '@/components/alert'
import {
  DeviceBreakpoints,
  useBrowserMediaQuery,
} from '@/common/hooks/use-browser-media-query'
import { useFlashMessagesContext } from '@/providers'

export function ProductFlashMessages(): JSX.Element {
  const { flashMessages } = useFlashMessagesContext()
  const isMd = useBrowserMediaQuery({ breakpoint: DeviceBreakpoints.Md })

  const refHiddenElement = useRef<HTMLDivElement>(null)

  const [isSticky, setIsSticky] = useState(false)
  const [topPosition, setTopPosition] = useState<number>(0)

  useEffect(() => {
    const handleScroll = () => {
      if (refHiddenElement.current) {
        const elementPosition =
          refHiddenElement.current.getBoundingClientRect().top
        const header =
          document.getElementById('header-id')?.offsetHeight ?? 1000000
        const topPosition = isMd ? header : 0
        const notVisibleBelowNotificationArea = elementPosition <= topPosition
        const notVisibleAboveNotificationArea =
          elementPosition >= window.innerHeight

        if (
          notVisibleBelowNotificationArea ||
          notVisibleAboveNotificationArea
        ) {
          setIsSticky(true)
          setTopPosition(topPosition)
        } else {
          setIsSticky(false)
        }
      }
    }

    if (flashMessages.length) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isMd, flashMessages])

  return (
    <>
      <div ref={refHiddenElement} className="invisible h-0" />
      <div
        className={twMerge('w-full', isSticky ? `fixed left-0 z-10` : 'block')}
        style={{ top: `${topPosition}px` }}
      >
        {flashMessages.map((message) => (
          <div
            key={message.id}
            className="z-50 animate-flashMessage [&_a]:text-secondary hover:[&_a]:text-secondary-hover mb-1"
          >
            <Alert severity={message.severity}>
              <span dangerouslySetInnerHTML={{ __html: message.text }} />
            </Alert>
          </div>
        ))}
      </div>
    </>
  )
}
