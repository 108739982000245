'use client'

import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslations } from 'next-intl'

import { GiftCardProductAmountFragment } from '@/api'
import { useCartContext, useStoreContext } from '@/providers'
import { ProductDetailVariantsAddToCartButton } from './variants-add-to-cart-button'
import { VariantsCounter } from './variants-counter'
import { Select } from '../select'
import { useAddToCartBtnText } from '@/common/hooks/use-add-to-cart-btn-text'
import { formatPrice } from '@/common/utils/price-utils'
import { useProductDataContext } from '@/providers/product-data/obsolote-product-data-context'

export type GiftVariantsProps = {
  productName: string
  sku: string
  giftCardAmounts: GiftCardProductAmountFragment[]
  isPopupVariant?: boolean
  onClose?: () => void
}

export const GiftVariants = ({
  productName,
  sku,
  giftCardAmounts,
  isPopupVariant,
  onClose,
}: GiftVariantsProps) => {
  const t = useTranslations('Product')

  const [changeText, setChangeText] = useState(false)

  const {
    storeCode,
    storeConfig: { currency },
  } = useStoreContext()
  const {
    giftCardProductAmount,
    setGiftCardProductAmount,
    productVariantCount,
    setProductVariantCount,
  } = useProductDataContext()
  const { isAddingToCart, isCartRefetching, addToCart, isCartFetchError } =
    useCartContext()
  const disabled = isCartRefetching || !addToCart

  const amounts = useMemo<GiftCardProductAmountFragment[]>(
    () =>
      (giftCardAmounts?.filter((amount) => !!amount) ??
        []) as GiftCardProductAmountFragment[],
    [giftCardAmounts],
  )

  const handleSelectChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const selectedValueIndex = e.target.value
      const selectedAmount = amounts?.find(
        (amount) => amount?.uid === selectedValueIndex,
      )
      if (selectedAmount) {
        setGiftCardProductAmount(selectedAmount)
      }
    },

    [amounts, setGiftCardProductAmount],
  )

  const buttonText = useAddToCartBtnText({
    changeText,
    isAddingToCart,
    isCartRefetching,
    isCartFetchError,
  })

  const handleAddToCart = addToCart
    ? async () => {
        setChangeText(true)
        await addToCart(
          {
            isGiftCard: true,
            sku,
            quantity: productVariantCount,
            selectedOptions: [giftCardProductAmount?.uid ?? ''],
            // additional info
            productName,
          },
          { skipTracking: true },
        )
        if (onClose) {
          onClose()
        }
      }
    : undefined

  useEffect(() => {
    if (!disabled) {
      setChangeText(false)
    }
  }, [disabled, setChangeText])

  return (
    <>
      <div className="my-4 flex flex-wrap md:flex-nowrap">
        <div className="w-full md:flex md:w-auto">
          <div className="flex-grow mb-5 md:mb-0 md:mr-2">
            <label className="mb-1 whitespace-nowrap text-sm block">
              {t('detail.variants.giftCardAmount')}
              <span className="text-red-600 ml-1.5">*</span>
            </label>
            <Select
              onChange={handleSelectChange}
              value={giftCardProductAmount?.uid ?? undefined}
              className="flex-grow text-sm md:min-w-[120px] min-h-[28px] bg-white"
              aria-label={t('detail.variants.giftCardAmount')}
              disabled={disabled}
            >
              {amounts.map((amount) => (
                <option key={amount.uid} value={amount?.uid ?? undefined}>
                  {formatPrice({
                    currency,
                    price: amount.website_value ?? 0,
                    storeCode,
                  })}
                </option>
              ))}
            </Select>
          </div>
        </div>
        <VariantsCounter
          count={productVariantCount}
          onCountChange={setProductVariantCount}
          onAddToCart={handleAddToCart}
          className={isPopupVariant ? 'w-[120px]' : 'w-[120px] md:w-[105px]'}
        />
      </div>
      <ProductDetailVariantsAddToCartButton
        className={isPopupVariant ? 'md:w-full lg:w-full' : undefined}
        addToCart={handleAddToCart}
        buttonText={buttonText}
      />
    </>
  )
}
